import React from 'react';
import Main from '../../main.module.css';

import Banner from '../../assets/images/aboutclimbing/climber.jpg';

import Cover from '../../components/Cover/Cover';
import Accordian from '../../components/Accordian/Accordian';

import Styles from './faq.module.css';
import { Link } from 'react-router-dom';

// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/faq',
//   title: 'Faq',
// });

const FAQ = () => {
  return (
    <div className={Styles.Faq}>
      <Cover height={350} image={Banner} overlay='centre'>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Frequently asked questions</h1>
          </div>
        </div>
      </Cover>
      <div className={Styles.Content}>
        <Accordian
          title='Can I just turn up?'
          content='Yes, you can, but please note that you will need to complete a waiver before climbing.'
        />
        <Accordian
          title='Who needs to complete the waiver?'
          content='All climbers must complete a waiver. For climbers under 18, the waiver can only be signed by a parent or legal guardian..'
        />
        <Accordian
          title='Can the waiver be completed online?'
          content={
            <>
              <p>Yes, the waiver can be completed online&nbsp;</p>
              <span style={{ color: 'blue !important' }}>
                <Link to='/register'>here</Link>
              </span>
              <p>&nbsp;or at the reception.</p>
            </>
          }
        />
        <Accordian
          title='What is the process for bouldering?'
          content='If you are over 18 and have bouldered before, you are good to go. If you are over 18 and have never bouldered before, you must first watch the bouldering safety video and successfully answer some related questions. For under 18s, they must be accompanied by either a boulder or rope competent adult.'
        />
        <Accordian
          title='What is the supervision requirement for under 18s in bouldering?'
          content='Under 18s must be supervised at all times, and the maximum number of under 18s that a boulder or rope competent adult can supervise is 2 with no swapping.'
        />
        <Accordian
          title='Can an adult with no bouldering experience supervise under 18s?'
          content="An adult with no bouldering experience may be allowed to supervise two under 18s, depending on their level of understanding ,from the bouldering induction video, of bouldering as judged by an Instructor from the Nottingham Climbing Centre. The Instructor's decision is final."
        />
        <Accordian
          title='Can 14-17 year olds boulder?'
          content='No, unless they have been assessed as competent at both ropes and bouldering.'
        />
        <Accordian
          title='What is the process for ropes?'
          content='If you are over 18 and have climbed elsewhere before, you must first pass a competency test. If you are over 18 and have never climbed before, you must be supervised by a competent ALL climbing adult/instructor or complete a Nottingham Climbing Centre induction first. For under 18s, they must be supervised by a competent ALL climbing adult at all times.'
        />
        <Accordian
          title='Can a novice 14-17 year old climb with ropes?'
          content='Novice 14-17 year olds must have been assessed as competent by a Nottingham Climbing Centre instructor (minimum of 6 hours with an instructor or minimum awarded NICAS level 2).'
        />

        <Accordian
          title='Do you offer birthday parties or group bookings?'
          content='Yes, we offer birthday parties and group bookings. Please contact us for more information and pricing.'
        />
        <Accordian
          title='What is the supervision requirement for novices in ropes?'
          content='A rope competent adult may supervise a maximum of 2 novices with no swapping. A novice cannot belay unless the rope is tailed by another competent climber.'
        />
        <Accordian
          title='Do I need to book?'
          content="Unless you're attending an instructor ran session, booking is not required for sessions. We will however require waivers if it is your first visit."
        />
        <Accordian
          title='Can I lead here?'
          content="Of course! We've got a plethora of sport routes, however you'll need to bring your own rope."
        />
        <Accordian
          title='How long can I climb for?'
          content='Once in you can climb for as long as you want until we close.'
        />
        <Accordian
          title='What should I wear when climbing?'
          content='We recommend wearing comfortable, stretchy clothing that allows you to move freely. Climbing shoes can be rented at our centre, but if you have your own, feel free to bring them along.'
        />
      </div>
    </div>
  );
};

export default FAQ;
