import React from 'react';
import Stepsystem from '../Stepsystem/Stepsystem';

import Styles from './PriceWidget.module.css';

const PriceWidget = () => {
  return (
    <div className={Styles.PriceWidget}>
      <Stepsystem>
        <Stepsystem.Step title='Day Pass'>
          <div className={Styles.StepBox}>
            {' '}
            <ul>
              <li>
                <p>Peak Adult *</p>
                <p>£11</p>
              </li>
              <li>
                <p>Off Peak Adult</p>
                <p>£10</p>
              </li>
              <li>
                <p>Concession</p>
                <p>£8.50</p>
              </li>
              <li>
                <p>Students & U16</p>
                <p>£8</p>
              </li>
              <li>
                <p>Fridays (Adult)</p>
                <p>£9</p>
              </li>
              <li>
                <p>Registration</p>
                <p>£6</p>
              </li>
              <li>
                <span
                  style={{
                    fontWeight: 300,
                  }}
                >
                  *Weekends & After 2pm weekdays
                </span>
              </li>
            </ul>{' '}
          </div>
        </Stepsystem.Step>
        <Stepsystem.Step title='Punch Card'>
          <div className={Styles.StepBox}>
            {' '}
            <ul>
              <li>
                <span className={Styles.Accent}>10 Punches</span>
              </li>
              <li>
                <p>Adult</p>
                <p>£90</p>
              </li>
              <li>
                <p>Concession</p>
                <p>£70</p>
              </li>
              <li>
                <p>Student & U16</p>
                <p>£60</p>
              </li>
              <li>
                <span className={Styles.Accent}>Gear Bundles</span>
              </li>
              <li>
                <p>3 Punches + Gear Incl.</p>
                <p>£35</p>
              </li>
            </ul>{' '}
          </div>
        </Stepsystem.Step>
        <Stepsystem.Step title='Monthly'>
          <div className={Styles.StepBox}>
            {' '}
            <ul>
              <li>
                <span className={Styles.Accent}>Rolling Monthly</span>
              </li>
              <li>
                <p>Adult</p>
                <p>£45</p>
              </li>
              <li>
                <p>Concession</p>
                <p>£40</p>
              </li>
              <li>
                <p>Students & U16</p>
                <p>£40</p>
              </li>
              <li>
                <span className={Styles.Accent}>Prepaid Monthly</span>
              </li>
              <li>
                <p>Adult</p>
                <p>£50</p>
              </li>
              <li>
                <p>Concession</p>
                <p>£45</p>
              </li>
              <li>
                <p>Students & U16</p>
                <p>£45</p>
              </li>
            </ul>{' '}
          </div>
        </Stepsystem.Step>
        <Stepsystem.Step title='Annually'>
          <div className={Styles.StepBox}>
            {' '}
            <ul>
              <li>
                <span className={Styles.Accent}>Prepaid Year</span>
              </li>
              <li>
                <p>Adult</p>
                <p>£450</p>
              </li>
              <li>
                <p>Concession</p>
                <p>£400</p>
              </li>
              <li>
                <p>Students & U16</p>
                <p>£400</p>
              </li>
            </ul>{' '}
          </div>
        </Stepsystem.Step>
      </Stepsystem>
    </div>
  );
};

export default PriceWidget;
