// sanity.js
import { createClient } from '@sanity/client';
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

let dateToday = new Date();
dateToday = dateToday.toISOString().split('T')[0];

// console.log(dateToday);

export const client = createClient({
  projectId: 'gr51k3c7',
  dataset: 'ncc_wo',
  // dataset: 'production',
  useCdn: false, // set to `false` to bypass the edge cache
  apiVersion: dateToday, // use current date (YYYY-MM-DD) to target the latest API version
  //   token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
});

// uses GROQ to query content: https://www.sanity.io/docs/groq

export async function getMonth() {
  const month = await client.fetch('*[_type == "whats_on"]');
  return month;
}
export async function getSeasonalCourses() {
  const courses = await client.fetch('*[_type == "seasonal_courses"]');
  return courses;
}
export async function getWeeklyCourses() {
  const courses = await client.fetch('*[_type == "weekly_events"]');
  return courses;
}
