import React from 'react';
import Main from '../../main.module.css';
import Styles from './aboutclimbing.module.css';
import GridrowStyle from '../../components/GridRow/gridrow.module.css';
import { Link } from 'react-router-dom';

import Banner from '../../assets/images/aboutclimbing/climber.jpg';
import Cover from '../../components/Cover/Cover';
import Button from '../../components/Button/Button';

import Bouldering from '../../assets/images/aboutclimbing/bouldering.jpg';
import Ropes from '../../assets/images/aboutclimbing/ropes.jpg';
import LinksBanner from '../../components/LinksBanner/LinksBanner';
import Gridrow from '../../components/GridRow/Gridrow';

// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/about/climbing',
//   title: 'New to climbing',
// });

const Aboutclimbing = () => {
  return (
    <div className={Styles.Aboutclimbing}>
      {' '}
      <Cover height={350} image={Banner} overlay='center'>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>NEW TO CLIMBING?</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/kids'>KIDS COURSES</Link>
        <Link to='/courses/beginner'>BEGINNERS COURSES</Link>
        <Link to='/courses/improvers'>IMPROVERS COURSES</Link>
      </LinksBanner>
      <div className={Styles.ContentWrapper}>
        <div className={Styles.Content}>
          <div className={Styles.Intro}>
            <h1>What do we offer?</h1>
            <p>
              If you're looking to give climbing a try, we offer two exciting
              and challenging disciplines: bouldering and rope climbing.
              Bouldering is all about testing your skills on short, powerful
              climbs while rope climbing takes it up a notch with longer, more
              difficult climbs. Both disciplines are great for beginners, so
              read on to find out more!
            </p>
          </div>

          <div className={GridrowStyle.Scrollable}>
            <Gridrow radius={35}>
              <Gridrow.Box>
                <div className={GridrowStyle.ContentBody}>
                  <span className={GridrowStyle.ContentBodyTitle}>
                    Bouldering
                  </span>
                  <br />
                  <p>
                    Bouldering involves climbing on walls that are lower in
                    height (~3m), using only climbing shoes and chalk for grip.
                    This means you can focus purely on the movement and
                    technique of climbing without worrying about ropes or
                    harnesses. With a range of different routes and
                    difficulties, bouldering is perfect for both beginners and
                    experienced climbers looking for a new challenge. We have a
                    range of bouldering sets which are suited for all abilities.
                  </p>
                </div>
              </Gridrow.Box>
              <Gridrow.Box bgimage={Bouldering} />
            </Gridrow>
            <Gridrow radius={35}>
              <Gridrow.Box>
                <div className={GridrowStyle.ContentBody}>
                  <span className={GridrowStyle.ContentBodyTitle}>
                    Roped Climbing
                  </span>
                  <br />
                  <p>
                    Rope climbing involves climbing higher walls using ropes and
                    harnesses to stay safe. Our gym offers top-rope climbing,
                    where the rope is already secured at the top of the wall,
                    and lead climbing, where you clip the rope into anchors as
                    you climb. Rope climbing is a great way to challenge
                    yourself both physically and mentally, as you navigate
                    different routes and learn how to use your body and
                    equipment effectively. Our experienced staff will teach you
                    the fundamentals of rope climbing and provide you with all
                    the necessary gear to get started.
                  </p>
                </div>
              </Gridrow.Box>
              <Gridrow.Box bgimage={Ropes} />
            </Gridrow>
          </div>
          {/* 
        <div className={cw(Styles.Bouldering, Styles.Panel)}>
          <h2>Bouldering</h2>
          <img src={Bouldering} alt='Bouldering' />
        </div>
        <div className={cw(Styles.Ropes, Styles.Panel)}>
          <h2>Roped Climbing</h2>
          <img src={Ropes} alt='Roped Climbing' />
          <p>
            Rope climbing involves climbing higher walls using ropes and
            harnesses to stay safe. Our gym offers top-rope climbing, where the
            rope is already secured at the top of the wall, and lead climbing,
            where you clip the rope into anchors as you climb. Rope climbing is
            a great way to challenge yourself both physically and mentally, as
            you navigate different routes and learn how to use your body and
            equipment effectively. Our experienced staff will teach you the
            fundamentals of rope climbing and provide you with all the necessary
            gear to get started.
          </p>
        </div> */}
          <div className={Styles.Extra}>
            <h1>Next steps</h1>
            <p>
              If you're new, you'll have needed to attend an introduction to
              climbing or bouldering (bouldering only access) to use our
              facilities. Additionally, if you would like to bring some younger
              ones, we offer a family taster which aims to get the parents
              trained up to bring kids.
            </p>
          </div>
          <div className={Styles.Links}>
            <Link to='/courses/kids'>
              <Button
                size='md'
                type='secondary'
                text='Courses for little ones'
                arrow
              />
            </Link>
            <Link to='/courses/beginner'>
              <Button
                size='md'
                type='primary'
                text='Courses for adults'
                arrow
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutclimbing;
