import React, { useState } from 'react';
import Styles from './Stepsystem.module.css';

const Stepsystem = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  const filteredSteps = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Step
  );

  return (
    <div className={Styles.Stepsystem}>
      <div className={Styles.Navigation}>
        {filteredSteps.map((child, index) => (
          <div
            key={index}
            className={`${Styles.Step} ${
              index === activeStep ? Styles.Active : ''
            }`}
            onClick={() => handleStepClick(index)}
          >
            {child.props.title}
          </div>
        ))}
      </div>

      <div className={Styles.Content}>
        {filteredSteps.length > 0
          ? filteredSteps[activeStep].props.children
          : null}
      </div>
    </div>
  );
};

const Step = ({ title, children }) => {
  return <div>{/* This component doesn't render anything directly */}</div>;
};

Stepsystem.Step = Step;

export default Stepsystem;
