import React from 'react';
import Main from '../../main.module.css';
import Styles from './courses.module.css';

import Banner from '../../assets/images/courses/kids/head_wall_banner.png';
import Wild_Climber from '../../assets/images/courses/kids/wild_climbers.jpg';
import Nicas from '../../assets/images/courses/kids/nicas.jpg';
import Kids_Party from '../../assets/images/courses/kids/kids_party.jpg';

import Nicas_Logo from '../../assets/images/courses/nicas.png';
import Wild_Climber_Logo from '../../assets/images/courses/wild_climbers.png';

import Cover from '../../components/Cover/Cover';
import Gridrow from '../../components/GridRow/Gridrow';
import Button from '../../components/Button/Button';
import LinksBanner from '../../components/LinksBanner/LinksBanner';
import { Link } from 'react-router-dom';

const Kidscourses = () => {
  return (
    <div className={`${Styles.Kidscourses} ${Styles.CoursePage}`}>
      <Cover height={350} image={Banner} overlay='center' mobileCollapse>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>KIDS CLIMBING COURSES</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/beginner'>BEGINNER COURSES</Link>
        <Link to='/courses/improvers'>IMPROVERS COURSES</Link>
        <Link to='/courses/seasonal'>SEASONAL COURSES</Link>
      </LinksBanner>

      <div className={Styles.Courses}>
        <Gridrow radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <div className={Styles.Far_Row}>
                <span className={Styles.CourseTitle}>
                  WILD
                  <br />
                  CLIMBERS
                </span>
                <img src={Wild_Climber_Logo} height='100px' alt='nicas' />
              </div>
              <p>
                <b>Ages 5-7 years</b>
                <br />
                NICAS Wild Climbers is a fun, game orientated programme that
                aims to introduce climbers safely into the indoor climbing
                environment, developing their awareness of climbing movements,
                improving confidence and creating a grassroots pathway into
                additional climbing activities. Wild Climbers is a programme
                that is designed to be inclusive and accessible for all.
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=540cdc3e2d254e74bdb374fcf5a7f8f1'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book Wild Climbers'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Wild_Climber} />
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box bgimage={Nicas} />
          <Gridrow.Box>
            <div className={Styles.Course}>
              <img src={Nicas_Logo} width='200px' alt='nicas' />
              <span
                style={{
                  fontSize: '2rem',
                  lineHeight: '2rem',
                  fontWeight: 600,
                }}
              >
                NATIONAL INDOOR CLIMBING AWARD SCHEME
              </span>
              <p>
                <b>Ages 7-18 years</b>
                <br />
                The NICAS Climbing scheme aims to teach children the skills to
                become competent climbers. Levels 1-3 focus on learning climbing
                fundamentals including bouldering techniques as well as belaying
                other climbers in a safe manner. We also offer levels 4 & 5, the
                children start to learn sport climbing, this involves learning a
                new way of belaying and climbing.
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=296b0e717f4c43b19eaefa0582a20798'>
                  <Button size='lg' type='secondary' text='Book NICAS' arrow />
                </a>
              </div>
            </div>
          </Gridrow.Box>
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>
                KIDS'
                <br />
                PARTIES
              </span>
              <p>
                <b>Ages 5+</b>
                <br />
                HAPPY BIRTHDAY! Celebrate with an hour of climbing with your
                friends. Learn a bit about bouldering and ropes with lots of
                games. We also have tunnel system called the Burrows! You can
                then relax in the party room for a bit and have some food and
                cake.
                <br />
              </p>
              <p>
                Cost: <b>£15pp (Minimum 4 kids)</b>
                <div>
                  <h3>Please ring for availability</h3>
                  {/* <Button size='lg' type='secondary' text='Book NICAS' arrow /> */}
                </div>
              </p>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Kids_Party} />
        </Gridrow>
      </div>
    </div>
  );
};

export default Kidscourses;
