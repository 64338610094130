import React, { useEffect, useState } from 'react';

import Main from '../../main.module.css';

// import Banner from '../../assets/images/aboutclimbing/climber.jpg';

import Cover from '../../components/Cover/Cover';

import Styles from './Whatson.module.css';

import { getMonth, client } from '../../sanity.js';
import { PortableText } from '@portabletext/react';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

const urlFor = (source) => {
  return builder.image(source);
};

const Whatson = () => {
  const [information, setInformation] = useState({ body: null });

  useEffect(() => {
    getMonth().then((data) => {
      console.log(data);
      setInformation(data[0]);
      // console.log(data[0]);
    });
  }, []);

  return (
    <div className={Styles.Whatson}>
      {information.body ? (
        <>
          <Cover
            height={350}
            image={urlFor(information.mainImage)}
            overlay='centre'
          >
            <div className={Styles.LandingContent}>
              <div className={Styles.Slogan}>
                <h1 className={Main.whiteText}>What's On</h1>
                <h2 style={{ fontWeight: 400 }} className={Main.whiteText}>
                  {information.month}
                </h2>
              </div>
            </div>
          </Cover>

          <div className={Styles.ContentWrapper}>
            <div className={Styles.Content}>
              <span className={Styles.date}>
                Published:{' '}
                {new Date(information.publishedAt).toISOString().split('T')[0]}
              </span>
              <PortableText value={information.body} />
            </div>
          </div>
        </>
      ) : (
        <div className={Styles.Loading}>Loading...</div>
      )}
    </div>
  );
};

export default Whatson;
