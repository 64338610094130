import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import Styles from '../app.module.css';

import Logo_Dark from '../assets/logos/ncc_dark.svg';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Dropdown from '../components/Dropdown/Dropdown';

import Home from '../pages/Home/Home';
import Kidscourses from '../pages/Courses/Kidscourses';
import Beginnercourses from '../pages/Courses/Beginnercourses';
import Improverscourses from '../pages/Courses/Improverscourses';
import Aboutclimbing from '../pages/Aboutclimbing/Aboutclimbing';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import FAQ from '../pages/FAQ/FAQ';
import Facilities from '../pages/Facilities/Facilities';
import Hooked from '../pages/Hooked/Hooked';
import Notfound from '../pages/404/Notfound';
import Prices from '../pages/Prices/Prices';
import Whatson from '../pages/Whatson/Whatson';
import SeasonalCourses from '../pages/Courses/Seasonalcourses';
// import Attentionbar from './Attentionbar/Attentionbar';
import { ElfsightWidget } from 'react-elfsight-widget';
import PrivacyPolicy from '../pages/PrivacyPolicy.js/PrivacyPolicy';
import Attentionbar from './Attentionbar/Attentionbar';

const Sandwhich = () => {
  return (
    <>
      {/* <Attentionbar height={30} backColour='#078b8c' textColour='#FFFFFF'>
        <p>
          <b>Half Term sessions </b>
          now bookable {''}
          <Link to='/courses/seasonal'>here</Link>
        </p>
      </Attentionbar>
      <Attentionbar height={30} backColour='#e74c3c' textColour='#FFFFFF'>
        <p>
          <b>We are closed to the general public on 4/11/23 due to YCS</b>
        </p>
      </Attentionbar> */}
      <Attentionbar height={30} backColour='#243551' textColour='#FFFFFF'>
        <p>
          Check out our new Massage Room{' '}
          <b>
            <Link to='https://www.themassageroomncc.com'>here!</Link>
          </b>
        </p>
      </Attentionbar>

      <ElfsightWidget widgetId='7630e7f6-9aa8-4b24-80bd-62ce7a49ed27' />
      <Header logo={Logo_Dark}>
        <Dropdown title='ABOUT CLIMBING'>
          <Dropdown.Option to='/about/climbing' title='NEW TO CLIMBING' />
          <Dropdown.Option to='/about/offerings' title='ALREADY HOOKED' />
          <Dropdown.Option to='/about/whats-on' title="WHAT'S ON" />
        </Dropdown>
        {/* <li>
          <Link to='/about/climbing'>ABOUT CLIMBING</Link>
        </li> */}
        <Dropdown title='COURSES'>
          <Dropdown.Option to='/courses/kids' title="KID'S COURSES" />
          <Dropdown.Option to='/courses/beginner' title='BEGINNER COURSES' />
          <Dropdown.Option to='/courses/improvers' title="IMPROVER'S COURSES" />
          <Dropdown.Option to='/courses/seasonal' title='SEASONAL COURSES' />
        </Dropdown>
        <li>
          <Link to='/about/prices'>PRICES</Link>
        </li>
        <li>
          <Link to='/faq'>FAQ</Link>
        </li>
        <li>
          <Link to='/about/facilities'>FACILITIES</Link>
        </li>
        <li>
          <Link to='/register'>
            <span className={Styles.Highlight}>REGISTER TO CLIMB</span>
          </Link>
        </li>
      </Header>

      <ScrollToTop />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/courses/kids' element={<Kidscourses />} />
        <Route path='/courses/beginner' element={<Beginnercourses />} />
        <Route path='/courses/improvers' element={<Improverscourses />} />
        <Route path='/courses/seasonal' element={<SeasonalCourses />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/about/prices' element={<Prices />} />
        <Route path='/about/climbing' element={<Aboutclimbing />} />
        <Route path='/about/facilities' element={<Facilities />} />
        <Route path='/about/offerings' element={<Hooked />} />
        <Route path='/about/whats-on' element={<Whatson />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/*' element={<Notfound />} />
      </Routes>

      <Footer
        logo={Logo_Dark}
        name='Nottingham Climbing Centre'
        instagram='nottinghamclimbing'
        facebook='nottinghamclimbing'
        twitter='nottinghamclimb'
      >
        <Footer.Column align='left'>
          <div className={Styles.Footer}>
            <h1>Site Links</h1>
            <Link to='/'>Home</Link>
            <Link to='/about/climbing'>New To Climbing</Link>
            <Link to='/about/offerings'>Already Hooked</Link>
            <Link to='/courses/kids'>Kids Courses</Link>
            <Link to='/courses/beginner'>Beginner Courses</Link>
            <Link to='/courses/improvers'>Improvers Courses</Link>
            <Link to='/register'>Waiver</Link>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </div>
        </Footer.Column>
        <Footer.Column align='left'>
          <div className={Styles.Footer}>
            <h1>Opening Hours</h1>
            <p>Monday - Friday : 12PM - 10PM</p>
            <p>Sat & Sun: 9AM - 8PM</p>

            <h1>Get In Touch</h1>
            <p>T: 0115 998 8233</p>
            <p>E: info@nottinghamclimbing.co.uk</p>

            <h1>Address</h1>
            <p>212 Noel St, New Basford, Nottingham NG7 7FR</p>
          </div>
        </Footer.Column>
      </Footer>
    </>
  );
};

export default Sandwhich;
