import React from 'react';
import Styles from './gridrow.module.css';
import cw from 'classnames';

const Gridrow = ({ height, children, radius }) => {
  return (
    <div
      style={{
        minHeight: `${height}px`,
        gridTemplateColumns: `repeat(2,1fr)`,
        borderRadius: `${radius}px`,
      }}
      className={Styles.Gridrow}
    >
      {children}
    </div>
  );
};

const Box = ({ bgimage, children }) => {
  return (
    <div className={Styles.Box}>
      <div className={cw(Styles.Content, bgimage ? Styles.Gradient : '')}>
        {children}
      </div>
      {bgimage && (
        <img src={bgimage} alt='backgroundImage' className={Styles.BoxImage} />
      )}
    </div>
  );
};

Gridrow.Box = Box;

export default Gridrow;
