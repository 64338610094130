import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Styles from './app.module.css';
// import Closed from './pages/Closed/Closed';
import Sandwhich from './components/Sandwhich';
import Register from './pages/Register/Register';

function App() {
  return (
    <div className={Styles.App}>
      <Router>
        <Routes>
          {/* <Route path='/*' element={<Closed />} /> */}
          <Route path='/register' element={<Register />} />
          <Route path='/*' element={<Sandwhich />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
