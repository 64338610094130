import React from 'react';
// import cw from 'classnames';

import Styles from './dropdown.module.css';
import { Link } from 'react-router-dom';

const Dropdown = ({ title, to, children }) => {
  if (to) {
    return (
      <ul className={Styles.Dropdown}>
        <Link to={to}>{title} ▾</Link>
        <ul className={Styles.Submenu}>{children}</ul>
      </ul>
    );
  } else {
    return (
      <ul className={Styles.Dropdown}>
        <p>{title} ▾</p>
        <ul className={Styles.Submenu}>{children}</ul>
      </ul>
    );
  }
};

const Option = (props) => {
  const { title, to, children } = props;

  if (!title && children) {
    return <li className={Styles.DropOption}>{children}</li>;
  } else if (to && title) {
    return (
      <li className={Styles.DropOption}>
        <Link to={to}>{title}</Link>
      </li>
    );
  } else {
    return <></>;
  }
};

Dropdown.Option = Option;

export default Dropdown;
