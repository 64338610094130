import React from 'react';
import Styles from './attentionbar.module.css';

const Attentionbar = ({ height, backColour, textColour, children }) => {
  return (
    <div
      style={{
        height: `${height}px`,
        backgroundColor: `${backColour}`,
        color: `${textColour}`,
      }}
      className={Styles.Attentionbar}
    >
      {children}
    </div>
  );
};

export default Attentionbar;
