import React from 'react';
import cw from 'classnames';

import Styles from './footer.module.css';

import Instagram_Icon from '../../assets/images/instagam_icon_dark.svg';
import Twitter_Icon from '../../assets/images/twitter_icon_dark.svg';
import Facebook_Icon from '../../assets/images/facebook_icon_dark.svg';

import { Link } from 'react-router-dom';

const Footer = ({
  children,
  description,
  logo,
  name,
  instagram,
  facebook,
  twitter,
}) => {
  return (
    <div className={Styles.Footer}>
      <div className={Styles.Footer_Wrapper}>
        <div className={Styles.Footer_Container}>
          <div className={cw(Styles.Footer_Column, Styles.Footer_Logo)}>
            <img src={logo} alt='logo' />
            <p style={{ fontSize: '1rem' }}>{description}</p>
            {instagram && (
              <a href={'https://www.instagram.com/' + instagram + '/'}>
                <div className={Styles.Social}>
                  <img src={Instagram_Icon} alt='instagram_icon' />
                  <p>@{instagram}</p>
                </div>
              </a>
            )}
            {facebook && (
              <a href={'https://www.facebook.com/' + facebook + '/'}>
                <div className={Styles.Social}>
                  <img src={Facebook_Icon} alt='facebook_icon' />
                  <p>@{facebook}</p>
                </div>
              </a>
            )}
            {twitter && (
              <a href={'https://twitter.com/' + twitter + '/'}>
                <div className={Styles.Social}>
                  <img src={Twitter_Icon} alt='twitter_icon' />
                  <p>@{twitter}</p>
                </div>
              </a>
            )}
          </div>
          <div className={Styles.Footer_Column_Wrapper}>{children}</div>
        </div>
      </div>
      <div className={Styles.Footer_Copyright}>
        <p>© 2024 {name.toUpperCase()}</p>
      </div>
    </div>
  );
};

const Column = ({ title, align, children }) => {
  return (
    <div style={{ textAlign: `${align}` }} className={Styles.Footer_Column}>
      {title && (
        <p>
          <b>{title}</b>
        </p>
      )}
      {children}
    </div>
  );
};

const SiteLink = ({ title, toLink }) => {
  return <Link to={toLink}>{title}</Link>;
};

Footer.Column = Column;
Footer.SiteLink = SiteLink;

export default Footer;
