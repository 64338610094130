import React from 'react';
import Main from '../../main.module.css';
import Styles from './courses.module.css';

import Training from '../../assets/images/courses/improvers/Training.jpg';
import Learn_To_Lead from '../../assets/images/courses/improvers/Learn_To_Lead.jpg';
import Bouldering_To_Ropes from '../../assets/images/courses/improvers/Bouldering_To_Ropes.jpg';

import Cover from '../../components/Cover/Cover';
import Gridrow from '../../components/GridRow/Gridrow';
import Button from '../../components/Button/Button';
import LinksBanner from '../../components/LinksBanner/LinksBanner';
import { Link } from 'react-router-dom';

// import ReactGA from 'react-ga4';

// Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/courses/improvers',
//   title: "Improver's Courses",
// });

const Improverscourses = () => {
  return (
    <div className={`${Styles.Improverscourses} ${Styles.CoursePage}`}>
      <Cover height={350} image={Training} overlay='center' mobileCollapse>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>IMPROVER CLIMBING COURSES</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/kids'>KIDS COURSES</Link>
        <Link to='/courses/beginner'>BEGINNERS COURSES</Link>
        <Link to='/courses/seasonal'>SEASONAL COURSES</Link>
      </LinksBanner>
      <div className={Styles.Courses}>
        <Gridrow radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>
                Bouldering to
                <br />
                Ropes
              </span>
              <p>
                <b>Ages 18+</b>
                <br />
                This session is aimed at those who want to progress from
                bouldering to roped climbing. We will cover top roped climbing,
                belaying and auto-belays, and this session will give you a good
                understanding of how to safely use the centre and the kit
                required to do so.
                <br />
                <br />
                Session Length: <b>2 Hour</b>
                <br />
                Cost: <b>£20pp</b>
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=fc8a636899d74191932007c7529a35d4'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book Bouldering Intro'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Bouldering_To_Ropes} />
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box bgimage={Learn_To_Lead} />
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>Learn to Lead</span>
              <p>
                <b>Ages 18+</b>
                <br />
                Already know how to top rope climb and are looking for the next
                step in your climbing journey? Then this is the place for you.
                Over either 2 or 3 two hour sessions, your instructor will teach
                you the basics of lead climbing, from clipping to belaying.
                <br />
                <br />
                <br />
                Session Length: <b>3 x 2 Hours</b>
                <br />
                Cost:{' '}
                <b>
                  £125 Private
                  <br />
                  £75 Open session
                </b>
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=3bb4e800395744eaa0cc4f3d32a0001a'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book Open Session'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
        </Gridrow>
      </div>
    </div>
  );
};

export default Improverscourses;
