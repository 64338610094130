import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cw from 'classnames';

import Styles from './Register.module.css';

import Flipper from '../../components/Flipper/Flipper';
import Button from '../../components/Button/Button';

import Logo from '../../assets/logos/ncc_dark.svg';
import Over18 from '../../assets/images/register/18p.svg';
import Under18 from '../../assets/images/register/u18.svg';
import Bouldering_Supervision from '../../assets/images/register/b_supervisor.svg';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/register',
//   title: 'Register',
// });

const Register = () => {
  const [currentWaiver, setCurrentWaiver] = useState(-1);
  const landingPanel = useRef(null);
  const lastPanel = useRef(null);
  const secondPanel = useRef(null);

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const selectOption = (option_number) => {
    setCurrentWaiver(option_number);
    scrollTo(secondPanel);
  };

  const goToWaiver = () => {
    switch (currentWaiver) {
      case 0:
        window.location.href =
          'https://app.rockgympro.com/waiver/esign/nottinghamclimbing/7a91c123-cb63-41fa-bef9-7c9b26c071d3';
        break;
      case 1:
        window.location.href =
          'http://app.rockgympro.com/waiver/esign/nottinghamclimbing/7d704230-b0b0-4907-9cdf-6a68788b7379';
        break;
      case 2:
        window.location.href =
          'https://app.rockgympro.com/waiver/esign/nottinghamclimbing/d242f694-cb10-4d08-b236-f27c3fe344af';
        break;
      default:
        scrollTo(landingPanel);
    }
  };

  return (
    <div className={Styles.Register}>
      <ScrollToTop />
      <div ref={landingPanel} className={cw(Styles.Options, Styles.Fullscreen)}>
        <Link to='/'>
          <img src={Logo} alt='nottingham_climbing_centre_logo' />
        </Link>
        <p>
          Before accessing our facilities we require a waiver to be filled out
          to make you aware of the risks associated with climbing and remove
          ambiguity in terms of liability in an accident. Anyone entering the
          climbing area will be required to fill a waiver in.
        </p>
        <h2>Choose from below</h2>
        <div className={Styles.Waivers}>
          <Flipper
            height={300}
            width={300}
            front={
              <div className={Styles.Icons}>
                <img src={Over18} alt='Over 18 Climber' />
                <h3>18+ Unsupervised</h3>
              </div>
            }
            back={
              <div className={Styles.Info}>
                <b>18+ Unsupervised</b>
                <p>
                  You use the same form for bouldering only and for climbing and
                  bouldering, just answer the questions appropriately and we
                  will discuss the waiver access with you. Bringing a friend? If
                  you are registered with us as an Competent Climbing Member you
                  can supervise up to two registered Novice Members.
                </p>
                <Button
                  type='primary'
                  size='sm'
                  text='Choose'
                  action={() => selectOption(0)}
                  arrow
                />
              </div>
            }
            color='#078b8c'
          />

          <Flipper
            height={300}
            width={300}
            front={
              <div className={Styles.Icons}>
                <img src={Under18} alt='-18 Supervised' />
                <h3>Under 18 Supervised</h3>
              </div>
            }
            back={
              <div className={Styles.Info}>
                <b>Under 18 Supervised</b>
                <p>
                  The form required parental/legal guardian consent
                  <br />
                  For under 18s being supervised by an adult. Supervision of the
                  child must be kept at all times in the centre.
                </p>
                <Button
                  type='primary'
                  size='sm'
                  text='Choose'
                  action={() => selectOption(1)}
                  arrow
                />
              </div>
            }
            color='#078b8c'
          />

          <Flipper
            height={300}
            width={300}
            front={
              <div className={Styles.Icons}>
                <img src={Bouldering_Supervision} alt='Bouldering Supervisor' />
                <h3>Bouldering Supervisor</h3>
              </div>
            }
            back={
              <div className={Styles.Info}>
                <b>Bouldering Supervisor</b>
                <p>
                  Please fill this form in if you have bouldered elsewhere
                  previously and are looking to bring novices bouldering with
                  you.
                </p>
                <Button
                  type='primary'
                  size='sm'
                  text='Choose'
                  action={() => selectOption(2)}
                  arrow
                />
              </div>
            }
            color='#078b8c'
          />
        </div>
      </div>
      <div ref={secondPanel} className={cw(Styles.Video, Styles.Fullscreen)}>
        <h2>Bouldering Safety Video</h2>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/VncW6cki2os?controls=0'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen
        />
        <p onClick={() => scrollTo(lastPanel)}>Already watched the video ?</p>
      </div>
      <div ref={lastPanel} className={cw(Styles.Link, Styles.Fullscreen)}>
        <Button
          size='lg'
          type='primary'
          text='Go to waiver'
          action={() => goToWaiver()}
          arrow
        />
      </div>
    </div>
  );
};

export default Register;
