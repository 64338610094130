import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './hooked.module.css';
import Course from '../Courses/courses.module.css';
import Main from '../../main.module.css';

import Gridrow from '../../components/GridRow/Gridrow';
import Cover from '../../components/Cover/Cover';
import Button from '../../components/Button/Button';

import Banner from '../../assets/images/hooked/progression.jpg';
import Bouldering from '../../assets/images/hooked/bouldering.jpg';
import Ropes from '../../assets/images/hooked/lead.jpg';
import Progression from '../../assets/images/hooked/bouldering-progression.jpg';
import LinksBanner from '../../components/LinksBanner/LinksBanner';
// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/about/offerings',
//   title: 'Already Hooked',
// });
const Hooked = () => {
  return (
    <div className={`${Styles.Hooked} ${Course.CoursePage}`}>
      <Cover height={350} image={Banner} overlay='center'>
        <div className={Styles.LandingContent}>
          <div className={Course.Slogan}>
            <h1 className={Main.whiteText}>Already hooked?</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/kids'>KIDS COURSES</Link>
        <Link to='/courses/beginner'>BEGINNERS COURSES</Link>
        <Link to='/courses/improvers'>IMPROVERS COURSES</Link>
      </LinksBanner>

      <div className={`${Styles.Content} ${Course.Courses}`}>
        <Gridrow radius={35}>
          <Gridrow.Box bgimage={Bouldering} />

          <Gridrow.Box>
            <div className={Styles.Offering}>
              <h2>Bouldering</h2>
              <p>
                At our climbing centre, we offer a range of bouldering
                challenges for climbers of all levels. Our bouldering routes are
                changed regularly to keep things fresh and exciting. For
                experienced climbers, we have some of the toughest and most
                challenging routes in the region. If you're new to bouldering,
                we recommend taking a course to learn the basics and ensure
                you're climbing safely.
                <br />
                <br />
                <b>
                  We require new climbers to fill out our waiver. To supervise
                  in bouldering without being rope competent, you will need to
                  fill out our bouldering supervision form. These can be done{' '}
                  <Link to='/register'>(here)</Link>
                </b>
              </p>

              <div>
                {/* <h3>Please ring for availability</h3> */}
                {/* <Button size='lg' type='secondary' text='Book NICAS' arrow /> */}
              </div>
            </div>
          </Gridrow.Box>
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box>
            <div className={Styles.Offering}>
              <h2>Ropes</h2>
              <p>
                For those who prefer rope climbing, we have a variety of
                challenging routes to choose from.{' '}
                <b>We enforce that new members take a competency test </b>with a
                member of our staff before climbing, so that we can ensure you
                have the skills and knowledge necessary to climb safely. Once
                you've passed the test, you're free to explore our range of rope
                and auto belay climbing challenges. Once passed, a belay
                competent adult is able to supervise up to <b>two novices</b> at
                rope climbing.
              </p>
              <a href='https://app.rockgympro.com/b/?&bo=b33436420df94851ab86d98c88a1278b'>
                <Button
                  size='md'
                  type='primary'
                  text='Introduction to Ropes'
                  arrow
                />
              </a>
              <a href='https://app.rockgympro.com/b/?&bo=b33436420df94851ab86d98c88a1278b'>
                <Button size='md' type='primary' text='Learn To Climb' arrow />
              </a>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Ropes} />
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box bgimage={Progression} />

          <Gridrow.Box>
            <div className={Styles.Offering}>
              <h2>Progression</h2>
              <p>
                If you're already an experienced climber but want to take your
                skills to the next level, we offer a range of progression
                sessions to help you achieve your goals. These sessions are
                designed to teach you advanced techniques and skills, and are
                led by our experienced instructors. Whether you want to improve
                your footwork, work on your technique, or learn to climb at a
                higher grade, we have a progression session for you. We believe
                that everyone can improve their climbing skills, no matter their
                level of experience, and we're here to help you reach your full
                potential. So why not book a session today and take your
                climbing to the next level?
              </p>
              <Link to='/courses/improvers'>
                <Button
                  size='md'
                  type='primary'
                  text='Improvers Sessions'
                  arrow
                />
              </Link>
            </div>
          </Gridrow.Box>
        </Gridrow>
      </div>
    </div>
  );
};

export default Hooked;
