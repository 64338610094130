import React from 'react';
import Styles from './PrivacyPolicy.module.css';
const PrivacyPolicy = () => {
  return (
    <div className={Styles.PrivacyPolicy}>
      <div className={Styles.PrivacyPolicyContent}>
        <section>
          <h1>Privacy Policy</h1>
          <small>
            This Policy was last reviewed and updated on: February 8th, 2024
          </small>
        </section>

        <p>
          At Nottingham Climbing Centre, we are committed to protecting the
          privacy and security of your personal information. This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your information
          when you visit our website.
        </p>
        <br />
        <p>
          By using our website, you consent to the practices described in this
          Privacy Policy. Please take a moment to carefully read the following
          information.
        </p>

        <section>
          <h2>1. Information We Collect</h2>

          <h3>1.1 Personal Information</h3>

          <p>
            When you visit our website, we may collect personally identifiable
            information, such as your name, email address, and phone number when
            provided voluntarily.
          </p>

          <h3>1.2 Non-Personal Information</h3>

          <p>
            We may also collect non-personal information, such as your IP
            address, browser type, device information, and usage patterns, to
            enhance the functionality of our website and improve user
            experience.
          </p>
        </section>
        <section>
          <h2>2. Use of Information</h2>

          <h3>2.1 Personal Information</h3>

          <p>
            We may use your personal information to communicate with you,
            provide requested information, and send promotional materials
            related to our services. Your personal information may also be used
            for marketing and advertising purposes, including the display of
            targeted ads.
          </p>

          <h3>2.2 Non-Personal Information</h3>

          <p>
            Non-personal information is primarily used to analyze trends,
            administer the website, track user movements, and gather demographic
            information for aggregate use.
          </p>
        </section>
        <section>
          <h2>3. Cookies and Tracking Technologies</h2>

          <p>
            We may use cookies and similar tracking technologies to enhance your
            browsing experience, personalize content, and collect information
            about how you interact with our website.
          </p>
        </section>
        <section>
          <h2>4. Third-Party Advertising</h2>

          <p>
            Our website may display advertisements from third-party ad networks.
            These ads may use cookies and similar technologies to provide
            personalized content and track user interactions.
          </p>
        </section>
        <section>
          <h2>5. Disclosure of Information</h2>

          <p>
            We may share your personal information with third-party service
            providers to assist with website operations and improve our
            services. However, we do not sell, trade, or otherwise transfer your
            personal information to outside parties without your consent.
          </p>
        </section>
        <section>
          <h2>6. Data Security</h2>

          <p>
            We implement security measures to protect your personal information
            from unauthorized access, disclosure, alteration, and destruction.
          </p>
        </section>
        <section>
          <h2>7. Your Choices</h2>

          <p>
            You have the right to opt-out of certain data collection and use.
            You can manage cookie preferences through your browser settings or
            opt-out of personalized advertising through ad network preferences.
          </p>
        </section>
        <section>
          <h2>8. Children's Privacy</h2>

          <p>
            Our website is not directed toward individuals under the age of 13,
            and we do not knowingly collect personal information from children.
          </p>
        </section>
        <section>
          <h2>9. Changes to this Privacy Policy</h2>

          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective upon posting the revised Privacy
            Policy on our website.
          </p>
        </section>
        <section>
          <h2>10. Contact Us</h2>

          <p>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at info@nottinghamclimbing.co.uk
          </p>

          <p>
            Thank you for trusting Nottingham Climbing Centre with your
            information.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
