import React from 'react';
import Styles from './facilities.module.css';
import Main from '../../main.module.css';

// import Banner from '../../assets/images/aboutclimbing/climber.jpg';
import Cover from '../../components/Cover/Cover';
import Green_Room from '../../assets/images/facilities/green_room.jpg';
import Ropes_Room from '../../assets/images/facilities/ropes_room.jpg';
import Shop from '../../assets/images/facilities/shop_room.jpg';
import Cafe from '../../assets/images/facilities/cafe.jpg';
import Training_Room from '../../assets/images/courses/improvers/Training.jpg';

// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/about/facilities',
//   title: 'Facilities',
// });

const Facilities = () => {
  return (
    <div className={Styles.Facilities}>
      <Cover height={500} image={Green_Room} overlay='left' filter>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Bouldering</h1>
            <ul>
              <li>Range of grades for all abilities</li>
              <li>4a - >7c</li>
            </ul>
          </div>
        </div>
      </Cover>
      <Cover height={500} image={Ropes_Room} overlay='right' filter>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Ropes</h1>
            <ul>
              <li>24 Lines of lead climbing</li>
              <li>28 Top Ropes</li>
              <li>7 Auto Belays</li>
              <li>4a - 7c</li>
              <li>Brand new state of the art wall</li>
            </ul>
          </div>
        </div>
      </Cover>
      <Cover height={500} image={Shop} overlay='left' filter>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Shop</h1>
            <ul>
              <li>Shoes</li>
              <li>Harnesses</li>
              <li>Climbing Accesssories</li>
            </ul>
          </div>
        </div>
      </Cover>
      <Cover height={500} image={Training_Room} overlay='Right' filter>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Training Room</h1>
            <ul>
              <li>State of the art digital training board</li>
              <li>Fingerboards</li>
              <li>Free Weights</li>
              <li>Circuit Board</li>
              <li>Splatter Board</li>
            </ul>
          </div>
        </div>
      </Cover>
      <Cover height={500} image={Cafe} overlay='Right' filter>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Cafe</h1>
            <ul>
              <li>Hot food</li>
              <li>Hot Drinks</li>
              <li>Cakes and Nibbles</li>
              <li>Toilets</li>
              <li>
                Portable ramp for disability access*
                <br />{' '}
              </li>
            </ul>
            <small>
              *Access available to cafe, viewing area and limited climbing
            </small>
          </div>
        </div>
      </Cover>
    </div>
  );
};

export default Facilities;
