import React from 'react';
// import Main from '../../main.module.css';
import Styles from './home.module.css';

import Block_Top_1 from '../../assets/images/home/block_pieces/block_top_1.svg';
import Block_Bottom_1 from '../../assets/images/home/block_pieces/block_bottom_1.svg';
import Block_Top_2 from '../../assets/images/home/block_pieces/block_top_2.svg';
import Block_Bottom_2 from '../../assets/images/home/block_pieces/block_bottom_2.svg';

import Nicas from '../../assets/images/home/NICAS.png';
import Centre from '../../assets/images/home/ropes_centre.png';

import Cover from '../../components/Cover/Cover';
import Angledblock from '../../components/Angledblock/Angledblock';
import Button from '../../components/Button/Button';

import Pool from '../../assets/images/home/ncc_pool.jpg';

//For mockup

import { Link } from 'react-router-dom';
import WhatsOnWidget from '../../components/WhatsOnWidget/WhatsOnWidget';

import { ElfsightWidget } from 'react-elfsight-widget';
import PriceWidget from '../../components/PricesWidget/PriceWidget';

// import ReactGA from 'react-ga4'; // Send pageview with a custom path
// ReactGA.send({   hitType: 'pageview',   page: '/',   title: 'Home', });

const Home = () => {
  return (
    <div className={Styles.Home}>
      <Cover height={700} image={Pool} overlay='left'>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Styles.PunchlineText}>
              Bouldering & Roped Wall based in Nottingham
            </h1>
            <h3 className={Styles.AccompanyText}>
              One of the longest running centres in the UK
            </h3>
            <div className={Styles.NewCustomerNavigation}>
              <Link to='/about/climbing'>
                <Button text="I'm a Beginner" size='md' type='primary' arrow />
              </Link>
              <Link to='/about/offerings'>
                <Button
                  text='I Climb Already'
                  size='md'
                  type='secondary'
                  arrow
                />
              </Link>
            </div>
          </div>
          <PriceWidget />
        </div>
      </Cover>

      <div>
        <Angledblock
          beginShape={Block_Top_1}
          endShape={Block_Bottom_1}
          fill={'#FFF'}
          children={
            <div className={Styles.Reviewables}>
              <span className={Styles.GoogleWidget}>
                <ElfsightWidget
                  widgetId={'9d88cab9-fd7f-47be-b263-cd38dd799d7f'}
                />
              </span>
              <img src={Nicas} alt='Nicas Accredited: Levels 1-5' />{' '}
            </div>
          }
        />
      </div>

      <div className={Styles.CourseGrid}>
        <div className={`${Styles.CourseFloat} ${Styles.FirstTime}`}>
          <h2>New to climbing</h2>
          <span>
            <Link to='/about/climbing'>
              <Button size='md' to='' text='Find Out More' type='primary' />
            </Link>
          </span>
        </div>
        <div className={`${Styles.CourseFloat} ${Styles.Adults}`}>
          <h2>Adults</h2>
          <span>
            <Link to='/courses/beginner'>
              <Button size='md' to='' text='Find Out More' type='primary' />
            </Link>
          </span>
        </div>
        <div className={`${Styles.CourseFloat} ${Styles.Kids}`}>
          <h2>Kids</h2>
          <span>
            <Link to='/courses/kids'>
              <Button size='md' to='' text='Find Out More' type='primary' />
            </Link>
          </span>
        </div>
        <div className={`${Styles.CourseFloat} ${Styles.Improvers}`}>
          <h2>Improvers</h2>
          <span>
            <Link to='/courses/improvers'>
              <Button size='md' to='' text='Find Out More' type='primary' />
            </Link>
          </span>
        </div>
      </div>

      <Angledblock
        beginShape={Block_Top_2}
        endShape={Block_Bottom_2}
        fill={'#FFF'}
        children={<WhatsOnWidget />}
      />

      <div className={Styles.FacilitiesWrapper}>
        <div className={Styles.Facilities}>
          <img src={Centre} alt='NCC Ropes' />
          <div>
            <h1>Facilities</h1>
            <ul>
              <li>24 Lines of Lead Climbing</li>
              <li>28 Top Rope Lines</li>
              <li>Two Dedicated Training Areas</li>
              <li>Bouldering ranging 4 - 7c</li>
              <li>7 Auto Belays</li>
              <li>Cafe with Super Fast Free Wifi and Log Burner</li>
              <li>Kids' Party Room</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={Styles.InstagramFeed}>
        <ElfsightWidget widgetId={'16cfe697-aa66-4236-a321-61e34e64d9d9'} />
      </div>
    </div>
  );
};

export default Home;
