import React from 'react';
import Styles from './flipper.module.css';

const Flipper = ({ height, width, color, front, back }) => {
  //   const [visibleContent, setVisibleContent] = useState(front);

  //   const showBack = () => {
  //     setVisibleContent(back);
  //   };

  //   const showFront = () => {
  //     setVisibleContent(front);
  //   };

  return (
    <div
      //   onMouseOver={() => showBack()}
      //   onMouseLeave={() => showFront()}
      className={Styles.Flipper}
      style={{ height: height + 'px', width: width + 'px' }}
    >
      <div
        className={Styles.Border}
        style={{ borderColor: color, color: color }}
      >
        <div style={{ color: color }} className={Styles.Front}>
          {front}
        </div>
        <div style={{ color: color }} className={Styles.Back}>
          {back}
        </div>
      </div>
    </div>
  );
};

// const Front = ({ children }) => {
//   return <div className={Styles.Front}>Front</div>;
// };

// const Back = ({ children }) => {
//   return <div className={Styles.Back}>Back</div>;
// };

// Flipper.Back = Back;
// Flipper.Front = Front;

export default Flipper;
