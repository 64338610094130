import React, { useState, useEffect } from 'react';
import Styles from './header.module.css';
import { useLocation } from 'react-router-dom';
import cw from 'classnames';

import { useMediaQuery } from 'react-responsive';

import { Link } from 'react-router-dom';

const Header = ({ children, logo }) => {
  const location = useLocation();
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);

  const isBigScreen = useMediaQuery({ query: '(min-width: 1440px)' });
  // const isMediumScreen = useMediaQuery({ query: '(min-width: 1025px)' });

  const handleMenuOpen = () => {
    setMobileHeaderOpen((current) => !current);
  };

  const closeMenu = () => {
    setMobileHeaderOpen(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div className={Styles.Header}>
      <div className={Styles.HeaderContainer}>
        <ul className={Styles.Brand}>
          <li className={Styles.Logo}>
            <Link to='/'>
              <img alt='Nottingham Climbing Centre' src={logo} />
            </Link>
          </li>
        </ul>
        {(mobileHeaderOpen || isBigScreen) && (
          <ul className={Styles.Navigation}>{children}</ul>
        )}
        <div className={Styles.MobileClose}>
          <button onClick={() => handleMenuOpen()}>
            <svg
              className={cw(
                Styles.burgericon,
                mobileHeaderOpen ? Styles.open : ''
              )}
              xmlns='http://www.w3.org/2000/svg'
              width='35'
              height='35'
            >
              <g className={Styles.icon}>
                <rect
                  className={Styles.frstbar}
                  x='5'
                  y='5'
                  width='25'
                  height='4'
                  fill='#414141'
                />
                <rect
                  className={Styles.scndbar}
                  x='5'
                  y='15'
                  width='25'
                  height='4'
                  fill='#414141'
                />
                <rect
                  className={Styles.thrdbar}
                  x='5'
                  y='25'
                  width='25'
                  height='4'
                  fill='#414141'
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div className={Styles.Shape}>
        <svg
          viewBox='0 0 1440 128'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0 -1H-1V0V90V91H0H169.42L189.132 125.496L189.42 126H190H450H450.58L450.868 125.496L470.58 91H1440H1441V90V0V-1H1440H0Z'
            fill='#ECF0F1'
          />
        </svg>
      </div>
    </div>
  );
};

export default Header;
