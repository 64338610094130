import React, { useState, useEffect } from 'react';
import Styles from './whatsonwidget.module.css';
import Stepsystem from '../Stepsystem/Stepsystem';
import Button from '../Button/Button';

import { getWeeklyCourses } from '../../sanity.js';
// import imageUrlBuilder from '@sanity/image-url';

// const builder = imageUrlBuilder(client);

// const urlFor = (source) => {
//   return builder.image(source);
// };

const WhatsOnWidget = () => {
  const [courses, setCourses] = useState([]);
  const [eventType, setEventType] = useState('all');

  useEffect(() => {
    getWeeklyCourses().then((data) => {
      setCourses(data);
      // console.log(data);
    });
  }, []);

  return (
    <div className={Styles.WhatsOnWidget}>
      <div className={Styles.Controls}>
        <h1>What's On</h1>
        <select
          name='event_type'
          id='event_select'
          defaultValue={eventType}
          onChange={(val) => setEventType(val.target.value)}
        >
          <option value='all'>All</option>
          <option value='socials'>Socials</option>
          <option value='adults'>Adults</option>
          <option value='kids'>Kids</option>
          <option value='beginners'>Beginners</option>
          <option value='improvers'>Improvers</option>
          <option value='family'>Family</option>
        </select>
      </div>
      <Stepsystem>
        <Stepsystem.Step title='Mondays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('monday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('monday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Tuesdays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('tuesday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('tuesday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Wednesdays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('wednesday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('wednesday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Thursdays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('thursday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('thursday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Fridays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('friday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('friday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Saturdays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('saturday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('saturday').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
        <Stepsystem.Step title='Sundays'>
          <div className={Styles.CourseHolder}>
            {courses.map((course, i) => {
              if (
                course.days.includes('sunday') &&
                (eventType === 'all' ? true : course.eventType === eventType)
              ) {
                return <WeeklyEvent key={`week_event_${i}`} course={course} />;
              }
              return null;
            })}
          </div>
          {courses.filter((course) =>
            course.days.includes('sundays').length === 0 ? (
              <p>No events</p>
            ) : null
          )}
        </Stepsystem.Step>
      </Stepsystem>
    </div>
  );
};

const WeeklyEvent = ({ course }) => {
  return (
    <div className={Styles.WeeklyEvent}>
      <div>
        <h2>{course.title}</h2>
        <p>{course.description}</p>
        <p>
          {course.startTime} - {course.endTime}
        </p>
      </div>
      {course.bookingLink && (
        <a href={course.bookingLink}>
          <Button size='sm' text='More Info' type='secondary' />
        </a>
      )}
    </div>
  );
};

export default WhatsOnWidget;
