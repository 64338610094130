import React from 'react';
import Main from '../../main.module.css';
import Styles from './courses.module.css';

import Banner from '../../assets/images/courses/beginners/Head_Wall_Banner.jpg';
import Bouldering_Intro from '../../assets/images/courses/beginners/Introduction_To_Bouldering.jpg';
import Family_Taster from '../../assets/images/courses/beginners/Taster.jpg';
import Refresher from '../../assets/images/courses/beginners/Refresher.jpg';
import Introduction_To_Ropes from '../../assets/images/courses/beginners/Bouldering_To_Ropes.jpg';
import Learn_To_Climb from '../../assets/images/courses/beginners/Learn_To_Climb.jpg';

import Cover from '../../components/Cover/Cover';
import Gridrow from '../../components/GridRow/Gridrow';
import Button from '../../components/Button/Button';
import LinksBanner from '../../components/LinksBanner/LinksBanner';
import { Link } from 'react-router-dom';

// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/courses/beginner',
//   title: "Beginner's Courses",
// });
const Beginnercourses = () => {
  return (
    <div className={`${Styles.Beginnercourses} ${Styles.CoursePage}`}>
      <Cover height={350} image={Banner} overlay='center' mobileCollapse>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>BEGINNER CLIMBING COURSES</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/kids'>KIDS COURSES</Link>
        <Link to='/courses/improvers'>IMPROVERS COURSES</Link>
        <Link to='/courses/seasonal'>SEASONAL COURSES</Link>
      </LinksBanner>

      <div className={Styles.Courses}>
        <Gridrow height={600} radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>
                Bouldering
                <br />
                Intro
              </span>
              <p>
                <b>Ages 18+</b>
                <br />
                This is a comprehensive introduction to un-roped climbing at
                Nottingham Climbing Centre. This session covers how to safely
                navigate a bouldering area, how to physically prepare for a
                session and how to interpret the grading system.
                <br />
                <br />
                Session Length: <b>1 Hour</b>
                <br />
                Cost: <b>£20pp (per hour)</b>
              </p>
              <div>
                <h3>Please ring for availability</h3>
                {/* <Button size='lg' type='secondary' text='Book NICAS' arrow /> */}
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Bouldering_Intro} />
        </Gridrow>

        <Gridrow height={600} radius={35}>
          <Gridrow.Box bgimage={Family_Taster} />
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>Family Taster</span>
              <p>
                <b>At least one adult and child per booking</b>
                <br />
                Thinking of trying a new activity with the kids? Climbing is a
                very exciting and fun family activity! The session will give
                everyone plenty of opportunity to to take part in roped and
                un-roped climbing. There is also the opportunity to teach the
                adults the skills required to become proficient in using the
                centre unsupervised. This is a session for at least one adult
                and one child per booking.
                <br />
                <br />
                Session Length: <b>2 Hours</b>
                <br />
                Cost: <b>£18.50pp</b>
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=c9b2475911cf4baf86d4965182657e19'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book a Family Taster'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
        </Gridrow>

        <Gridrow height={600} radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>Refresher</span>
              <p>
                <b>Ages 18+</b>
                <br />
                This is a highly variable session depending on what you need!
                Haven't climbed since the first lockdown and need to refresh
                your belay skills? Want an instructor to give you some tips and
                tricks? Want your child to come down to try out climbing 1:1
                with an instructor? This session can provide them and more!
                <br />
                <br />
                Session Length: <b>1 Hour</b>
                <br />
                Cost: <b>£30pp</b>
              </p>
              <div>
                <h3>Please ring for availability</h3>
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Refresher} />
        </Gridrow>

        <Gridrow height={600} radius={35}>
          <Gridrow.Box bgimage={Introduction_To_Ropes} />
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>
                Introduction To
                <br />
                Ropes
              </span>
              <p>
                <b>Ages 18+</b>
                <br />
                This session is aimed at those who want to get into climbing,
                either for the first time or or after a taster. We will cover
                un-roped climbing and top roped climbing, and this session will
                give you a good understanding of how to safely use the centre
                and the kit required to do so. This is a session for people who
                are 18 and over.
                <br />
                <br />
                Session Length: <b>2 Hours</b>
                <br />
                Cost:{' '}
                <b>
                  £50 for 1:1
                  <br />
                  £30pp for 2-4 people
                </b>
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=5336646781494aefb0791c0968e58ff0'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book Introduction to Ropes'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
        </Gridrow>

        <Gridrow height={600} radius={35}>
          <Gridrow.Box>
            <div className={Styles.Course}>
              <span className={Styles.CourseTitle}>Learn To Climb</span>
              <p>
                <b>Ages 18+ years</b>
                <br />
                This session is aimed at those who want to get into climbing,
                either for the first time or or after a taster. We will cover
                un-roped climbing and top roped climbing, and this session will
                give you a good understanding of how to safely use the centre
                and the kit required to do so.
                <br />
                <br />
                Session Length: <b>3 x 2 Hours</b>
                <br />
                Cost:{' '}
                <b>
                  £100 Private
                  <br />
                  £75pp Open session
                </b>
              </p>
              <div>
                <a href='https://app.rockgympro.com/b/?&bo=b33436420df94851ab86d98c88a1278b'>
                  <Button
                    size='lg'
                    type='secondary'
                    text='Book a Climbing Intro'
                    arrow
                  />
                </a>
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={Learn_To_Climb} />
        </Gridrow>
      </div>
    </div>
  );
};

export default Beginnercourses;
