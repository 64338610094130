import React, { useEffect, useState } from 'react';

import Main from '../../main.module.css';

import Banner from '../../assets/images/courses/back_corridor.jpg';

import Cover from '../../components/Cover/Cover';

import Styles from './courses.module.css';

import { client, getSeasonalCourses } from '../../sanity.js';
import { PortableText } from '@portabletext/react';
import imageUrlBuilder from '@sanity/image-url';
import Button from '../../components/Button/Button';
import Gridrow from '../../components/GridRow/Gridrow';
import LinksBanner from '../../components/LinksBanner/LinksBanner.js';
import { Link } from 'react-router-dom';

const builder = imageUrlBuilder(client);

const urlFor = (source) => {
  return builder.image(source);
};

const SeasonalCourses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getSeasonalCourses().then((data) => {
      setCourses(data);
      // console.log(data);
    });
  }, []);

  return (
    // <p>d</p>
    <div className={`${Styles.Seasonalcourses} ${Styles.CoursePage}`}>
      <Cover height={350} image={Banner} overlay='center' mobileCollapse>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>SEASONAL COURSES</h1>
          </div>
        </div>
      </Cover>
      <LinksBanner>
        <Link to='/courses/kids'>KIDS COURSES</Link>
        <Link to='/courses/beginner'>BEGINNERS COURSES</Link>
        <Link to='/courses/improvers'>IMPROVERS COURSES</Link>
      </LinksBanner>
      <div className={Styles.Courses}>
        {courses.map((course, index) => (
          <Gridrow height={600} radius={30}>
            {index % 2 !== 0 && (
              <Gridrow.Box bgimage={urlFor(course.mainImage)} />
            )}
            <Gridrow.Box>
              <div className={Styles.Course}>
                <span
                  style={{
                    fontSize: '2rem',
                    lineHeight: '2rem',
                    fontWeight: 600,
                  }}
                >
                  {course.title}
                </span>
                <p>
                  <b>Ages {course.age_range}</b>
                  <br />
                  <PortableText value={course.description} />
                  <br />
                  <br />
                  Session Length:{' '}
                  <b>
                    {course.session_length}{' '}
                    {course.session_length > 1 ? 'Hours' : 'Hour'}
                  </b>
                  <br />
                  Cost: <b>£{course.price}pp</b>
                </p>
                <div>
                  {course.booking_link ? (
                    <a href={course.booking_link}>
                      <Button
                        size='lg'
                        type='secondary'
                        text='Book now'
                        arrow
                      />
                    </a>
                  ) : (
                    <h3>Please ring for availability</h3>
                  )}
                </div>
              </div>
            </Gridrow.Box>
            {index % 2 === 0 && (
              <Gridrow.Box bgimage={urlFor(course.mainImage)} />
            )}
          </Gridrow>
        ))}
        {courses.length === 0 && (
          <center>
            <h2>Unfortunately, no seasonal courses are currently running.</h2>
          </center>
        )}
      </div>
    </div>
  );
};

export default SeasonalCourses;
