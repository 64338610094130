import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'modern-css-reset';
// import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from 'register-service-worker';
// import ReactGA from 'react-ga4';
// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       cacheTime: 1000 * 60 * 60 * 24, // 24 hours
//     },
//   },
// });
// ReactGA.initialize('G-X0K4HJ99RV');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <QueryClientProvider client={queryClient}> */}
    <App />
    {/* </QueryClientProvider> */}
  </React.StrictMode>
);

serviceWorkerRegistration.register();
