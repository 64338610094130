import React from 'react';
import Styles from './angledblock.module.css';

const Angledblock = ({ beginShape, endShape, children, fill }) => {
  // TODO - Add beginShape as ::before and same for endShape. Then make them on figma for home screen, should work properly then

  return (
    <div className={Styles.Angledblock}>
      <div className={Styles.BeforeShape}>
        <img alt='beginshape' src={beginShape} />
      </div>
      <div style={{ backgroundColor: `${fill}` }} className={Styles.MainBlock}>
        {children}
      </div>
      <div className={Styles.AfterShape}>
        <img alt='endshape' src={endShape} />
      </div>
    </div>
  );
};

export default Angledblock;
