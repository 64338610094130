import React, { useState } from 'react';
import Styles from './accordian.module.css';

const Accordian = ({ title, content }) => {
  const [openState, setOpenState] = useState(false);

  const toggleAccordian = () => {
    setOpenState((current) => !current);
  };

  return (
    <div onClick={() => toggleAccordian()} className={Styles.Accordian}>
      <div className={Styles.Title}>{title}</div>
      {openState && <div className={Styles.Content}>{content}</div>}
    </div>
  );
};

export default Accordian;
