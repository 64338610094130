import React from 'react';
import Styles from './Notfound.module.css';

// import ReactGA from 'react-ga4';

// // Send pageview with a custom path
// ReactGA.send({
//   hitType: 'pageview',
//   page: '/404',
//   title: '404',
// });

const Notfound = () => {
  return (
    <div className={Styles.Notfound}>
      <h1>404</h1>
      <h2>That page doesn't seem to exist!</h2>
    </div>
  );
};

export default Notfound;
