import React from 'react';
import Styles from './button.module.css';
import cw from 'classnames';

const getSize = (sizeInput) => {
  switch (sizeInput) {
    case 'sm':
      return Styles.sm;
    case 'md':
      return Styles.md;
    case 'lg':
      return Styles.lg;
    case 'fll':
      return Styles.fll;
    default:
      return Styles.md;
  }
};
const getType = (typeInput) => {
  switch (typeInput) {
    case 'primary':
      return Styles.Primary;
    case 'secondary':
      return Styles.Secondary;
    default:
      return Styles.Primary;
  }
};

const Button = ({ type, size, text, action, arrow }) => {
  return (
    <button
      onClick={action}
      className={cw(Styles.Button, getType(type), getSize(size))}
    >
      <p>{text}</p>
      {arrow && <span className={Styles.Arrow}>→</span>}
    </button>
  );
};

export default Button;
