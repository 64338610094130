import React from 'react';

import Styles from './prices.module.css';
import GridrowStyle from '../../components/GridRow/gridrow.module.css';
import Main from '../../main.module.css';

import Gridrow from '../../components/GridRow/Gridrow';
import Button from '../../components/Button/Button';
import PriceWidget from '../../components/PricesWidget/PriceWidget';
import { Link } from 'react-router-dom';
import Cover from '../../components/Cover/Cover';

import punch_card from '../../assets/images/prices/punch_card.jpg';
import membership from '../../assets/images/prices/membership.jpg';
import gift_card from '../../assets/images/prices/gift_card.jpg';
import Banner from '../../assets/images/home/ncc_pool.jpg';

const Prices = () => {
  return (
    <div className={Styles.Prices}>
      <Cover height={500} image={Banner} overlay='center'>
        <div className={Styles.LandingContent}>
          <div className={Styles.Slogan}>
            <h1 className={Main.whiteText}>Prices</h1>
          </div>
          <PriceWidget />
        </div>
      </Cover>

      <div className={GridrowStyle.ScrollableFull}>
        <Gridrow radius={35}>
          <Gridrow.Box>
            <div className={GridrowStyle.ContentBody}>
              <span className={GridrowStyle.ContentBodyTitle}>Punch Cards</span>
              <p>
                <br />
                Our Punch Cards are a simple and efficient way to enjoy your
                climbing sessions. Each card comes with ten punches, perfect for
                regular climbers without any commitments. These punch cards are
                not only cost-effective but also easy to use, making your
                climbing experience smoother and more manageable. Grab your card
                and start your climbing journey now!
                <br />
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Adult</td>
                    <td>
                      <Link to='https://app.rockgympro.com/b/?&bo=906a2311359e40cc86df35f622954e02'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='secondary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Junior (U16)</td>
                    <td>
                      {' '}
                      <Link to='https://app.rockgympro.com/b/?&bo=01854e30ae4a4d40a5c693d82c5e32aa'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='secondary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Concession/Student</td>
                    <td>
                      <p>Please speak to reception for more info.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={punch_card} />
        </Gridrow>

        <Gridrow radius={35}>
          <Gridrow.Box bgimage={membership} />

          <Gridrow.Box>
            <div className={GridrowStyle.ContentBody}>
              <span className={GridrowStyle.ContentBodyTitle}>Memberships</span>
              <p>
                <br />
                Experience convenience and savings with our Rolling and Prepaid
                Memberships for climbing enthusiasts.
                <br />
                Our Rolling Membership offers a cost-friendly rate that renews
                automatically each month, providing consistent access to your
                climbing passions. Hassle-free and economical, it's perfect for
                climbers who love to frequently use our wall. <br />
                Alternatively, opt for our Prepaid Membership, a one-time
                payment option providing the same access without the monthly
                renewal. Ideal for those who prefer to pay upfront, it's a great
                way to maintain your climbing regime.
                <br />
                Choose the membership that suits your lifestyle best, and let
                your climbing adventure continue!
                <br />
                <br />
                <b>Please see reception for concession/student memberships.</b>
              </p>
              <br />
              <br />
              <h2>Prepaid Monthly</h2>
              <table>
                <tbody>
                  <tr>
                    <td>Adult</td>
                    <td>
                      <Link to='https://app.rockgympro.com/b/?&bo=bdd67bc982c54d42aa4b39ef964a5109'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Junior (U16)</p>
                    </td>
                    <td>
                      {' '}
                      <Link to='https://app.rockgympro.com/b/?&bo=f298ca1d11ad40c7af6913277cc068bc'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>Rolling Monthly</h2>
              <table>
                <tbody>
                  <tr>
                    <td>Adult</td>
                    <td>
                      <Link to='https://app.rockgympro.com/b/?&bo=66f36d6b75ec44398faefcc03e455722'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>Junior (U16)</p>
                    </td>
                    <td>
                      {' '}
                      <Link to='https://app.rockgympro.com/b/?&bo=5b31dc8ca93a413f8be81b83564cff58'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>Prepaid Year</h2>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Adult</p>
                    </td>
                    <td>
                      <Link to='https://app.rockgympro.com/b/?&bo=358ac025a2694ef48849735a5626fc6f'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>Junior (U16)</p>
                    </td>
                    <td>
                      {' '}
                      <Link to='https://app.rockgympro.com/b/?&bo=533e8b72e41e40c49cd40656b8aee1cc'>
                        <Button
                          size='sm'
                          text='Purchase'
                          type='primary'
                          arrow
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Gridrow.Box>
        </Gridrow>

        <Gridrow height={600} radius={35}>
          <Gridrow.Box>
            <div className={GridrowStyle.ContentBody}>
              <span className={GridrowStyle.ContentBodyTitle}>Gift Cards</span>
              <br />
              <p>
                Gift the thrill of climbing with our Gift Cards - an ideal
                surprise for every climbing enthusiast you know.
                <br />
                Unlike other presents, our gift cards allow you to choose the
                value, providing flexibility and personalization. They offer the
                recipients full access to our climbing facilities, whether
                they're beginners or seasoned climbers. Easy to purchase and
                even easier to use, these gift cards are your solution to
                gift-giving dilemmas. Give the gift of adventure today!
                <br />
                <br />
              </p>
              <div>
                <Link to='https://app.rockgympro.com/b/?&bo=giftcard-4c3de9543e5a4b5ea10d9a241ccdd4ce'>
                  <Button size='md' type='secondary' text='Purchase' arrow />
                </Link>
              </div>
            </div>
          </Gridrow.Box>
          <Gridrow.Box bgimage={gift_card} />
        </Gridrow>
      </div>
    </div>
  );
};

export default Prices;
